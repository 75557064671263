import Vue from 'vue'
import Vuetify from 'vuetify'
import VueClipboard from 'vue-clipboard2'
import VueCryptojs from 'vue-cryptojs'
import VueNativeNotification from 'vue-native-notification'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import 'srcdoc-polyfill/srcdoc-polyfill.min.js'

Vue.use(VueClipboard)
Vue.use(Vuetify)
Vue.use(VueCryptojs)
Vue.use(VueNativeNotification, {
  requestOnNotify: true
})
export default new Vuetify({
  lang: {
    current: 'en'
  },
  breakpoint: {
    mobileBreakpoint: 'sm',
    scrollBarWidth: 12,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1264,
      lg: 1904
    }
  },
  theme: {
    dark: false,
    default: 'light',
    themes: {
      light: {
        primary: '#223a6b',
        secondary: '#657186',
        accent: '#3f87f5',
        error: '#f56c6c',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        urgent: '#d80000',
        pending: '#cbcb00',
        behind: '#ff7043',
        open: '#674646',
        completed: '#35b400',
        darkblue: '#1b2847'
      },
      dark: {
        primary: '#2196F3',
        secondary: '#424242',
        accent: '#FF4081',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00'
      }
    },
    options: {
      customProperties: true,
      cspNonce: 'dQw4w9WgXc2'
    }
  },
  icons: {
    iconfont: 'mdi',
    values: {
      notification: 'notification_important',
      success: 'check',
      error: 'error'
    }
  }
})
