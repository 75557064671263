export default {
    name: 'CustomDialog',
    props: {
        title: { type: String, default: '' },
        content: { type: String, default: '' },
        button1Text: { type: String, default: 'Cancel' },
        button2Text: { type: String, default: 'Delete' },
        hasButton1: { type: Boolean, default: true },
        hasButton2: { type: Boolean, default: true },
        open: { type: Boolean, default: false },
        value: { type: Boolean, default: false },
        mainBtnDisabled: { type: Boolean, default: false },
        hasFooter: { type: Boolean, default: true },
        minHeight: { type: [Number, String], default: null },
        maxWidth: { type: [Number, String], default: '600px' },
        extraBodyClass: { type: String, default: '' },
        fullscreen: { type: Boolean, default: false }
    },

    data: () => ({
        dialog: false,
        loading: false,
        btnloading: false
    }),

    mounted() {
        this.$event.$on('btnloading_off', () => {
            this.btnloading = false
        })
    },

    watch: {
        value: {
            handler(val) {
                this.dialog = val
                if (val) {
                    this.btnloading = false
                }
            },
            immediate: true
        },
        open(new_val) {
            this.dialog = new_val
            this.btnloading = false
            this.$event.$emit('chat_hidden', new_val ? this.fullscreen : false)
        },
        dialog(val) {
            this.$emit('update:open', val)
            this.$emit('input', val)
        }
    },

    methods: {
        button1clicked() {
            this.$emit('button1')
            this.close_dialog()
        },

        button2clicked() {
            this.btnloading = true
            this.$emit('button2')
        },

        open_dialog() {
            this.dialog = true
        },

        close_dialog() {
            this.dialog = false
            this.btnloading = false
            this.$emit('click:close', true)
            this.$emit('click-close', true)
        },

        clear_and_close() {
            Object.assign(this.$data, this.$options.data.apply(this))
            this.close_dialog() //close the modal
        },

        activate_loading() {
            this.loading = true
        },

        disable_loading() {
            this.loading = false
        }
    }
}